import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChargingSpacesRoutingModule } from './charging-spaces-routing.module';
import { ChargingSpacesListComponent } from './charging-spaces-list/charging-spaces-list.component';
import { ClarityModule } from '@clr/angular';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [ChargingSpacesListComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    ClarityModule,
    ChargingSpacesRoutingModule,
  ],
})
export class ChargingSpacesModule {}
