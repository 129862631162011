import { Component } from '@angular/core';
import { ReservableHoursService } from '../services/reservable-hours.service';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReservableHours } from 'src/app/interfaces/reservableHours';

@Component({
  selector: 'app-reservable-hours-form',
  templateUrl: './reservable-hours-form.component.html',
  styleUrls: ['./reservable-hours-form.component.css'],
})
export class ReservableHoursFormComponent {
  displayError = false;
  errorMessage = '';
  days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  model: ReservableHours = {
    country_code: '',
    party_id: '',
    id: '',
    twentyfourseven: false,
    regular_hours: [],
    exceptional_openings: [],
    exceptional_closings: [],
  };

  form!: FormGroup;

  constructor(
    private _hoursService: ReservableHoursService,
    private _router: Router,
    private _formBuilder: FormBuilder,
  ) {
    this.form = this._formBuilder.group({
      id: ['', Validators.required],
      twentyfourseven: [false, Validators.required],

      monday: this._formBuilder.group({
        active: [false, Validators.required],
        periods: this._formBuilder.array([this.createPeriod()]),
      }),
      tuesday: this._formBuilder.group({
        active: [false, Validators.required],
        periods: this._formBuilder.array([this.createPeriod()]),
      }),
      wednesday: this._formBuilder.group({
        active: [false, Validators.required],
        periods: this._formBuilder.array([this.createPeriod()]),
      }),
      thursday: this._formBuilder.group({
        active: [false, Validators.required],
        periods: this._formBuilder.array([this.createPeriod()]),
      }),
      friday: this._formBuilder.group({
        active: [false, Validators.required],
        periods: this._formBuilder.array([this.createPeriod()]),
      }),
      saturday: this._formBuilder.group({
        active: [false, Validators.required],
        periods: this._formBuilder.array([this.createPeriod()]),
      }),
      sunday: this._formBuilder.group({
        active: [false, Validators.required],
        periods: this._formBuilder.array([this.createPeriod()]),
      }),

      exceptional_openings: this._formBuilder.array([]),
      exceptional_closings: this._formBuilder.array([]),
    });
  }

  createPeriod(): FormGroup {
    return this._formBuilder.group({
      period_begin: '',
      period_end: '',
    });
  }

  createExceptional(): FormGroup {
    return this._formBuilder.group({
      date: ['', Validators.required],
      period_begin: ['', Validators.required],
      period_end: ['', Validators.required],
    });
  }

  isActive(day: string) {
    return this.form.get(day)?.get('active')?.value;
  }

  getPeriods(day: string) {
    return this.form.get(day)?.get('periods') as FormArray;
  }

  addPeriod(day: string) {
    this.getPeriods(day).push(this.createPeriod());
  }

  deletePeriod(day: string, index: number) {
    this.getPeriods(day).removeAt(index);
  }

  getOpenings() {
    return this.form.get('exceptional_openings') as FormArray;
  }

  addOpening() {
    this.getOpenings().push(this.createExceptional());
  }

  deleteOpening(index: number) {
    this.getOpenings().removeAt(index);
  }

  getClosings() {
    return this.form.get('exceptional_closings') as FormArray;
  }

  addClosing() {
    this.getClosings().push(this.createExceptional());
  }

  deleteClosing(index: number) {
    this.getClosings().removeAt(index);
  }

  onSubmit() {
    if (this.form.valid) {
      // Reset form errors
      this.displayError = false;
      this.errorMessage = '';

      // Update object
      this.model.id = this.form.value.id;
      this.model.twentyfourseven = this.form.value.twentyfourseven;

      // Parse Regular Hours, ignore if 24/7 is set
      if (!this.model.twentyfourseven) {
        this.days.forEach((day, index) => {
          const periods = this.getPeriods(day).value;

          periods.forEach(
            (period: { period_begin: string; period_end: string }) => {
              if (period.period_begin && period.period_end) {
                this.model.regular_hours.push({
                  weekday: index + 1,
                  period_begin: period.period_begin,
                  period_end: period.period_end,
                });
              }
            },
          );
        });
      }

      // Parse Exceptional Openings
      this.getOpenings().value.forEach((opening: FormHours) => {
        const start = new Date(opening.date);
        const end = new Date(opening.date);
        start.setHours(parseInt(opening.period_begin.split(':')[0]));
        start.setMinutes(parseInt(opening.period_begin.split(':')[1]));

        end.setHours(parseInt(opening.period_end.split(':')[0]));
        end.setMinutes(parseInt(opening.period_end.split(':')[1]));

        this.model.exceptional_openings.push({
          period_begin: start,
          period_end: end,
        });
      });

      // Parse Exceptional Closings
      this.getClosings().value.forEach((closing: FormHours) => {
        const start = new Date(closing.date);
        const end = new Date(closing.date);
        start.setHours(parseInt(closing.period_begin.split(':')[0]));
        start.setMinutes(parseInt(closing.period_begin.split(':')[1]));

        end.setHours(parseInt(closing.period_end.split(':')[0]));
        end.setMinutes(parseInt(closing.period_end.split(':')[1]));

        this.model.exceptional_closings.push({
          period_begin: start,
          period_end: end,
        });
      });

      // Call service
      this._hoursService.putReservableHours(this.model).subscribe({
        next: (resp) => {
          if (resp.status_code != 1000) {
            // Tracks errors returned from the server
            this.displayError = true;
            this.errorMessage = `Error ${resp.status_code}: ${resp.status_message}`;
          } else {
            this._router.navigate(['/reservable-hours']);
          }
        },
        // Tracks errors from the service call itself i.e. server unreachable
        error: () => {
          this.displayError = true;
        },
      });
    }
  }

  // This reset form will clear all of the inputs however it will not delete the inputs that have been created dynamically
  // TODO: Reset form completely so it appears as it does on load.
  resetForm() {
    this.form.reset();
  }
}

interface FormHours {
  date: Date;
  period_begin: string;
  period_end: string;
}
