import { ChargingSpace } from 'src/app/interfaces/chargingSpace';
import { ChargingSpacesService } from 'src/app/charging-spaces/services/charging-spaces.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Tariff } from 'src/app/interfaces/tariff';
import { TariffListComponent } from '../tariff-list/tariff-list.component';
import { TariffService } from '../services/tariff.service';

@Component({
  selector: 'app-tariff-assign',
  templateUrl: './tariff-assign.component.html',
  styleUrls: ['./tariff-assign.component.css'],
  providers: [TariffListComponent],
})

// This component is a mirror of configuration assignment
export class TariffAssignComponent {
  displayError = false;
  errorMessage = '';

  tariffs: Tariff[] = [];
  charging_spaces: ChargingSpace[] = [];

  selected_tariff!: Tariff;
  selected_spaces: ChargingSpace[] = [];

  constructor(
    private _chargingSpacesService: ChargingSpacesService,
    private _tariffService: TariffService,
    private _router: Router,
    public tariffListComponent: TariffListComponent,
  ) {
    this._tariffService.getTariffs().subscribe((resp) => {
      this.tariffs = resp.data;
    });

    this._chargingSpacesService.getSpaces().subscribe((resp) => {
      this.charging_spaces = resp.data;
    });
  }

  onSubmit() {
    if (this.selected_spaces.length >= 1 && this.selected_tariff) {
      this.displayError = false;
      this.errorMessage = '';

      this._tariffService
        .assignTariff(this.selected_tariff, this.selected_spaces)
        .subscribe({
          next: (resp) => {
            if (resp.status_code != 1000) {
              // Tracks errors returned from the server
              this.displayError = true;
              this.errorMessage = `Error ${resp.status_code}: ${resp.status_message}`;
            } else {
              this._router.navigate(['/tariffs']);
            }
          },
          // Tracks errors from the service call itself i.e. server unreachable
          error: () => {
            this.displayError = true;
          },
        });
    }
  }
}
