import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReservableHoursRoutingModule } from './reservable-hours-routing.module';
import { ReservableHoursListComponent } from './reservable-hours-list/reservable-hours-list.component';
import { ReservableHoursFormComponent } from './reservable-hours-form/reservable-hours-form.component';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReservableHoursAssignComponent } from './reservable-hours-assign/reservable-hours-assign.component';

@NgModule({
  declarations: [
    ReservableHoursListComponent,
    ReservableHoursFormComponent,
    ReservableHoursAssignComponent,
  ],
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    ReservableHoursRoutingModule,
  ],
})
export class ReservableHoursModule {}
