import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app-config.service';
import { ChargingSpace } from 'src/app/interfaces/chargingSpace';
import { ReservableHours } from 'src/app/interfaces/reservableHours';
import {
  ReservableHoursArrayResponse,
  Response,
} from 'src/app/interfaces/response';

@Injectable({
  providedIn: 'root',
})
export class ReservableHoursService {
  private API_GATEWAY = '';
  private country_code = '';
  private party_id = '';

  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService,
    private _keycloak: KeycloakService,
  ) {
    this.API_GATEWAY = this._appConfig.getConfig().API_GATEWAY;

    const token = this._keycloak.getKeycloakInstance().tokenParsed;
    if (token) {
      this.country_code = token['country_code'];
      this.party_id = token['party_id'];
    }
  }

  getReservableHours(): Observable<ReservableHoursArrayResponse> {
    const url = `${this.API_GATEWAY}/reservable_hours`;
    return this._http.get<ReservableHoursArrayResponse>(url);
  }

  putReservableHours(hours: ReservableHours): Observable<Response> {
    hours.country_code = this.country_code;
    hours.party_id = this.party_id;
    const url = `${this.API_GATEWAY}/reservable_hours/${hours.id}`;
    return this._http.put<Response>(url, hours);
  }

  assignReservableHours(
    hours: ReservableHours,
    spaces: ChargingSpace[],
  ): Observable<Response> {
    const url = `${this.API_GATEWAY}/reservable_hours/${hours.id}/assignments`;
    const data = {
      assignments: spaces.map((space) => ({
        location_id: space.location_id,
        evse_uid: space.uid,
      })),
    };
    return this._http.post<Response>(url, data);
  }
}
