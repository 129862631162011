import { Component } from '@angular/core';
import {
  ClarityIcons,
  boltIcon,
  clockIcon,
  plusIcon,
  trashIcon,
  userIcon,
} from '@cds/core/icon';

// TODO: Replace with rnc svg
const rncIconSvg = `<svg viewBox="-37.592 -51.282 249.055 249.364" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse style="fill-rule: nonzero; paint-order: fill; fill: rgb(255, 255, 255);" cx="86.935" cy="73.4" rx="122.359" ry="122.359"/>
<path d="M 71.23 34.099 C 71.23 31.378 74.562 30.062 76.421 32.049 L 104.53 62.1 L 123.43 82.3 L 124.738 83.727 C 125.306 84.347 126.109 84.7 126.95 84.7 L 171.618 84.7 C 174.243 84.7 175.602 81.568 173.81 79.651 L 107.619 8.851 C 107.052 8.244 106.259 7.9 105.428 7.9 L 2.435 7.9 C -0.188 7.9 -1.548 11.03 0.242 12.947 L 45.241 61.147 C 45.808 61.755 46.602 62.1 47.434 62.1 L 68.23 62.1 C 69.887 62.1 71.23 60.757 71.23 59.1 L 71.23 34.099 Z" fill="#09C6FF"/>
<path d="M 98.53 84.7 C 96.873 84.7 95.53 86.043 95.53 87.7 L 95.53 109.638 C 95.53 112.393 92.128 113.692 90.293 111.637 L 66.23 84.7 L 47.025 63.106 C 46.456 62.466 45.64 62.1 44.784 62.1 L 2.252 62.1 C -0.343 62.1 -1.714 65.172 0.019 67.103 L 63.536 137.903 C 64.105 138.538 64.917 138.9 65.769 138.9 L 171.41 138.9 C 174.065 138.9 175.411 135.705 173.557 133.805 L 126.513 85.604 C 125.948 85.026 125.174 84.7 124.366 84.7 L 98.53 84.7 Z" fill="#4024F3"/>
<path d="M70.7001 76.8L50.6001 54.2L70.7001 76.8Z" fill="#4024F3"/>
<path d="M130.1 76.7999L127.9 74.3999L130.1 76.7999Z" fill="white"/>
</svg>`;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'rnc-dashboard';

  constructor() {
    ClarityIcons.addIcons(['rnc-icon', rncIconSvg]);
    ClarityIcons.addIcons(boltIcon, clockIcon, userIcon, plusIcon, trashIcon);
  }
}
