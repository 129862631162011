import { ChargingSpace } from 'src/app/interfaces/chargingSpace';
import { ChargingSpacesService } from 'src/app/charging-spaces/services/charging-spaces.service';
import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/interfaces/config';
import { ConfigService } from '../services/config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-config-assign',
  templateUrl: './config-assign.component.html',
  styleUrls: ['./config-assign.component.css'],
})
export class ConfigAssignComponent implements OnInit {
  displayError = false;
  errorMessage = '';

  configs: Config[] = [];
  charging_spaces: ChargingSpace[] = [];

  selected_config!: Config;
  selected_spaces: ChargingSpace[] = [];

  constructor(
    private _chargingSpacesService: ChargingSpacesService,
    private _configService: ConfigService,
    private _router: Router,
  ) {}

  ngOnInit() {
    this._configService.getConfigs().subscribe((resp) => {
      this.configs = resp.data;
    });

    this._chargingSpacesService.getSpaces().subscribe((resp) => {
      this.charging_spaces = resp.data;
    });
  }

  onSubmit() {
    if (this.selected_spaces.length >= 1 && this.selected_config) {
      this.displayError = false;
      this.errorMessage = '';

      this._configService
        .assignConfig(this.selected_config, this.selected_spaces)
        .subscribe({
          next: (resp) => {
            if (resp.status_code != 1000) {
              // Tracks errors returned from the server
              this.displayError = true;
              this.errorMessage = `Error ${resp.status_code}: ${resp.status_message}`;
            } else {
              this._router.navigate(['/configurations']);
            }
          },
          // Tracks errors from the service call itself i.e. server unreachable
          error: () => {
            this.displayError = true;
          },
        });
    }
  }
}
