import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/services/auth-guard/auth.guard';
import { ConfigAssignComponent } from './config-assign/config-assign.component';
import { ConfigFormComponent } from './config-form/config-form.component';
import { ConfigListComponent } from './config-list/config-list.component';
import { NgModule } from '@angular/core';
import { PageLayoutComponent } from '../page-layout/page-layout.component';

const ConfigRoutes: Routes = [
  {
    path: 'configurations',
    component: PageLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ConfigListComponent },
      { path: 'create', component: ConfigFormComponent },
      { path: 'assign', component: ConfigAssignComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ConfigRoutes)],
  exports: [RouterModule],
})
export class ConfigRoutingModule {}
