import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageLayoutComponent } from '../page-layout/page-layout.component';
import { AuthGuard } from '../auth/services/auth-guard/auth.guard';
import { ReservationsListComponent } from './reservations-list/reservations-list.component';

const routes: Routes = [
  {
    path: 'reservations',
    component: PageLayoutComponent,
    canActivate: [AuthGuard],
    children: [{ path: '', component: ReservationsListComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReservationsRoutingModule {}
