<!-- Alert bar -->
<div class="alert alert-danger" role="alert" *ngIf="displayError">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
      </div>
      <span
        class="alert-text"
        *ngIf="
          errorMessage;
          then displayErrorMessage;
          else displayGenericMessage
        "
      >
      </span>
    </div>
  </div>
</div>

<ng-template #displayErrorMessage>
  {{ errorMessage }}
</ng-template>
<ng-template #displayGenericMessage>
  Something went wrong. Please try again later.
</ng-template>

<!-- Config Selection -->
<label for="config-select">Select Configuration:</label>
<select clrSelect name="config-select" [(ngModel)]="selected_config">
  <option value="" disabled selected>Select configuration</option>
  <option *ngFor="let config of configs" [ngValue]="config">
    {{ config.id }}
  </option>
</select>

<!-- Config Details -->
<table class="table table-vertical table-compact table-rightheader">
  <tbody>
    <tr>
      <th>Min. Stay</th>
      <td>{{ selected_config ? selected_config.min_stay_minutes : null }}</td>
    </tr>
    <tr>
      <th>Max. Stay</th>
      <td>{{ selected_config ? selected_config.max_stay_minutes : null }}</td>
    </tr>
    <tr>
      <th>Reservable Period</th>
      <td>
        {{ selected_config ? selected_config.reservable_period_minutes : null }}
      </td>
    </tr>
    <tr>
      <th>Buffer Period</th>
      <td>
        {{ selected_config ? selected_config.buffer_period_minutes : null }}
      </td>
    </tr>
  </tbody>
</table>
<br />

<!-- Space selection -->
<p class="p2">Select EVSEs to apply configuration to:</p>
<clr-datagrid [(clrDgSelected)]="selected_spaces">
  <clr-dg-column>EVSE ID</clr-dg-column>
  <clr-dg-column>Location ID</clr-dg-column>
  <clr-dg-column>UID</clr-dg-column>
  <clr-dg-column>Config ID</clr-dg-column>
  <clr-dg-column>Last Updated</clr-dg-column>

  <clr-dg-row *clrDgItems="let space of charging_spaces" [clrDgItem]="space">
    <clr-dg-cell>{{ space.evse_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.location_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.uid }}</clr-dg-cell>
    <clr-dg-cell>{{ space.config_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.last_updated | date: "d/M/yy, H:mm" }}</clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer
    >{{ charging_spaces.length }}
    {{ charging_spaces.length === 1 ? "space" : "spaces" }}</clr-dg-footer
  >
</clr-datagrid>

<button
  [disabled]="selected_spaces.length < 1 || !selected_config"
  class="btn btn-primary"
  (click)="onSubmit()"
  type="submit"
>
  Assign
</button>
