<a
  routerLink="/reservable-hours/create"
  routerLinkActive="active"
  class="btn btn-sm"
>
  Add Reservable Hours
</a>
<a
  routerLink="/reservable-hours/assign"
  routerLinkActive="active"
  class="btn btn-sm"
>
  Assign Reservable Hours
</a>
<clr-datagrid>
  <clr-dg-column [clrDgField]="'id'">Hours ID</clr-dg-column>
  <clr-dg-column>Regular Hours</clr-dg-column>
  <clr-dg-column>Exceptional Openings?</clr-dg-column>
  <clr-dg-column>Exceptional Closings?</clr-dg-column>

  <clr-dg-row *clrDgItems="let item of reservableHours">
    <clr-dg-cell>{{ item.id }}</clr-dg-cell>
    <clr-dg-cell>{{
      item.twentyfourseven
        ? "24/7"
        : getAvailableDays(item)
        ? getAvailableDays(item)
        : "Closed"
    }}</clr-dg-cell>
    <clr-dg-cell>
      <input
        type="checkbox"
        clrCheckbox
        disabled
        [checked]="item.exceptional_openings.length > 0"
      />
    </clr-dg-cell>
    <clr-dg-cell
      ><input
        type="checkbox"
        clrCheckbox
        disabled
        [checked]="item.exceptional_closings.length > 0"
    /></clr-dg-cell>
  </clr-dg-row>
</clr-datagrid>
