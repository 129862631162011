import {
  DayOfWeekEnum,
  TariffElement,
  Tariff,
} from 'src/app/interfaces/tariff';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TariffService } from '../services/tariff.service';

@Component({
  selector: 'app-tariff-form',
  templateUrl: './tariff-form.component.html',
  styleUrls: ['./tariff-form.component.css'],
})
export class TariffFormComponent {
  tariffForm!: FormGroup;

  displayError = false;
  errorMessage = '';

  dayOfWeek = DayOfWeekEnum;

  tariffModel: Tariff = {
    id: '',
    country_code: '',
    party_id: '',
    currency: '',
    tariff_alt_text: null,
    elements: [],
    last_updated: null,
  };

  constructor(
    private _tariffService: TariffService,
    private _router: Router,
    public datepipe: DatePipe,
  ) {}

  ngOnInit(): void {
    // Construct the form
    this.tariffForm = new FormGroup({
      id: new FormControl('', Validators.required),
      currency: new FormControl('GBP', Validators.required),
      tariff_alt_text: new FormControl(''),
      elements: new FormArray([this.initElementFormGroup()]),
    });
  }

  getElements(form: any) {
    return form.controls.elements.controls;
  }

  // Expect to receive the elements form
  getPriceComponents(form: any) {
    return form.controls.price_components.controls;
  }

  addTariffElement() {
    const control = <FormArray>this.tariffForm.get('elements');
    control.push(this.initElementFormGroup());
  }

  addPriceComponent(elementIndex: any) {
    const control = <FormArray>this.tariffForm.get('elements');
    const elementControl = <FormArray>(
      control.at(elementIndex).get('price_components')
    );
    elementControl.push(this.initPriceComponentFormGroup());
  }

  initElementFormGroup() {
    return new FormGroup({
      restrictions: new FormGroup({
        start_time: new FormControl(null),
        end_time: new FormControl(null),
        start_date: new FormControl('', Validators.required),
        end_date: new FormControl(null),
        min_duration_minutes: new FormControl(0),
        max_duration_minutes: new FormControl(0),
        day_of_week: new FormControl('', Validators.required),
      }),
      price_components: new FormArray([this.initPriceComponentFormGroup()]),
    });
  }

  toggleElementInputs(element: FormGroup) {
    const elementRestrictions = <FormGroup>element.controls['restrictions'];
    if (elementRestrictions.disabled) {
      elementRestrictions.enable();
    } else {
      elementRestrictions.disable();
    }
  }

  initPriceComponentFormGroup() {
    return new FormGroup({
      type: new FormControl('RESERVED_STAY_FLAT', Validators.required),
      price: new FormControl(0, Validators.required),
      vat: new FormControl(20, Validators.required),
      step_size: new FormControl(0, Validators.required),
    });
  }

  onSubmit() {
    if (this.tariffForm.valid) {
      // Reset form errors
      this.displayError = false;
      this.errorMessage = '';

      // Convert tariff alt text into expected format
      this.tariffForm.value.tariff_alt_text = [
        {
          language: 'en',
          text: this.tariffForm.value.tariff_alt_text,
        },
      ];

      // Update object and call service function
      this.tariffModel = Object.assign(this.tariffModel, this.tariffForm.value);

      this.tariffModel.elements?.forEach((element: TariffElement) => {
        if (element.restrictions) {
          try {
            element.restrictions!.start_date = this.datepipe.transform(
              element.restrictions!.start_date,
              'yyyy-MM-dd',
            );
            element.restrictions!.end_date = this.datepipe.transform(
              element.restrictions!.end_date,
              'yyyy-MM-dd',
            );
          } catch (error) {
            this.displayError = true;
            this.errorMessage =
              'Dates must be of format DD-MM-YYYY or YYYY-MM-DD';
          }
        } else {
          element.restrictions = null;
        }
      });

      this.tariffModel.last_updated = new Date();

      if (!this.displayError) {
        this._tariffService.putTariff(this.tariffModel).subscribe({
          next: (resp) => {
            if (resp.status_code != 1000) {
              // Tracks errors returned from the server
              this.displayError = true;
              this.errorMessage = `Error ${resp.status_code}: ${resp.status_message}`;
            } else {
              this._router.navigate(['/tariffs']);
            }
          },
          // Tracks errors from the service call itself i.e. server unreachable
          error: () => {
            this.displayError = true;
          },
        });
      }
    }
  }

  GetValidDays(element: FormGroup) {
    if (element.value.restrictions) {
      const dayOfWeekJsonString = element.value.restrictions.day_of_week
        ? element.value.restrictions.day_of_week.join()
        : 'None';
      return dayOfWeekJsonString;
    } else {
      return 'Any';
    }
  }
}
