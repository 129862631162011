import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReservationsRoutingModule } from './reservations-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ClarityModule } from '@clr/angular';
import { ReservationsListComponent } from './reservations-list/reservations-list.component';

@NgModule({
  declarations: [ReservationsListComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    ClarityModule,
    ReservationsRoutingModule,
  ],
})
export class ReservationsModule {}
