import { Component, OnInit } from '@angular/core';
import { ReservableHours } from 'src/app/interfaces/reservableHours';
import { ReservableHoursService } from '../services/reservable-hours.service';

@Component({
  selector: 'app-reservable-hours-list',
  templateUrl: './reservable-hours-list.component.html',
  styleUrls: ['./reservable-hours-list.component.css'],
})
export class ReservableHoursListComponent implements OnInit {
  reservableHours!: ReservableHours[];

  constructor(private _hoursService: ReservableHoursService) {}

  ngOnInit() {
    this._hoursService.getReservableHours().subscribe((resp) => {
      this.reservableHours = resp.data;
    });
  }

  getAvailableDays(hours: ReservableHours) {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    let availableDays = hours.regular_hours.map((item) => {
      return days[item.weekday - 1];
    });

    availableDays = availableDays.filter((item, index, self) => {
      return index === self.indexOf(item);
    });

    return availableDays.join(', ');
  }
}
