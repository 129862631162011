import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeycloakAngularModule } from 'keycloak-angular';

@NgModule({
  declarations: [],
  imports: [
    KeycloakAngularModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
})
export class AuthModule {}
