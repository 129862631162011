import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.css'],
})
export class PageLayoutComponent {
  username = '';

  constructor(private _keycloak: KeycloakService) {
    const token = this._keycloak.getKeycloakInstance().tokenParsed;
    if (token) {
      this.username = token['email'];
    }
  }

  async logOut() {
    await this._keycloak.logout(window.location.origin);
  }
}
