import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppConfigService } from './app-config.service';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { BrowserModule } from '@angular/platform-browser';
import { ChargingSpacesModule } from './charging-spaces/charging-spaces.module';
import { ClarityModule } from '@clr/angular';
import { ConfigModule } from './config/config.module';
import { DatePipe } from '@angular/common';
import { HomeModule } from './home/home.module';
import { KeycloakService } from 'keycloak-angular';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ReservationsModule } from './reservations/reservations.module';
import { TariffsModule } from './tariff/tariffs.module';
import { firstValueFrom } from 'rxjs';
import locale from '@angular/common/locales/en-GB';
import { registerLocaleData } from '@angular/common';

registerLocaleData(locale);
import { ReservableHoursModule } from './reservable-hours/reservable-hours.module';

export function initApp(
  appConfig: AppConfigService,
  keycloak: KeycloakService,
) {
  return async () => {
    return firstValueFrom(appConfig.loadConfig()).then(async () => {
      return await keycloak.init({
        config: {
          url: appConfig.getConfig().IDENTITY_SERVICE,
          realm: 'rnc-dev',
          clientId: appConfig.getConfig().CLIENT_ID,
        },
        initOptions: {
          pkceMethod: 'S256',
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
            window.location.origin + '/assets/silent-check-sso.html',
        },
      });
    });
  };
}

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, PageLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ClarityModule,
    AuthModule,
    HomeModule,
    ReservationsModule,
    TariffsModule,
    ChargingSpacesModule,
    ConfigModule,
    ReservableHoursModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppConfigService, KeycloakService],
      multi: true,
    },
    [DatePipe],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
