<!-- Alert bar -->
<div class="alert alert-danger" role="alert" *ngIf="displayError">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
      </div>
      <span
        class="alert-text"
        *ngIf="
          errorMessage;
          then displayErrorMessage;
          else displayGenericMessage
        "
      >
      </span>
    </div>
  </div>
</div>

<ng-template #displayErrorMessage>
  {{ errorMessage }}
</ng-template>
<ng-template #displayGenericMessage>
  Something went wrong. Please try again later.
</ng-template>

<!-- Tariff Selection -->
<label for="tariff-select">Select Tariff:</label>
<select clrSelect name="tariff-select" [(ngModel)]="selected_tariff">
  <option value="" disabled selected>Select tariff</option>
  <option *ngFor="let tariff of tariffs" [ngValue]="tariff">
    {{ tariff.id }}
  </option>
</select>

<!-- Config Details -->
<table class="table table-vertical table-compact table-rightheader">
  <tbody>
    <tr>
      <th>Alternate text</th>
      <td>
        {{
          selected_tariff &&
          selected_tariff.tariff_alt_text &&
          selected_tariff.tariff_alt_text.length > 0
            ? selected_tariff.tariff_alt_text[0].text
            : "None"
        }}
      </td>
    </tr>
    <tr>
      <th>Valid days</th>
      <td>
        {{
          (selected_tariff
            ? tariffListComponent.getDaysTariffApplies(selected_tariff)
            : null
          ) | titlecase
        }}
      </td>
    </tr>
  </tbody>
</table>
<br />

<!-- Space selection -->
<p class="p2">Select EVSEs to apply tariff to:</p>
<clr-datagrid [(clrDgSelected)]="selected_spaces">
  <clr-dg-column>EVSE ID</clr-dg-column>
  <clr-dg-column>Location ID</clr-dg-column>
  <clr-dg-column>UID</clr-dg-column>
  <clr-dg-column>Config ID</clr-dg-column>
  <clr-dg-column>Tariff ID</clr-dg-column>
  <clr-dg-column>Last Updated</clr-dg-column>

  <clr-dg-row *clrDgItems="let space of charging_spaces" [clrDgItem]="space">
    <clr-dg-cell>{{ space.evse_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.location_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.uid }}</clr-dg-cell>
    <clr-dg-cell>{{ space.config_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.reservation_tariff_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.last_updated | date: "d/M/yy, H:mm" }}</clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer
    >{{ charging_spaces.length }}
    {{ charging_spaces.length === 1 ? "space" : "spaces" }}</clr-dg-footer
  >
</clr-datagrid>

<button
  [disabled]="selected_spaces.length < 1 || !selected_tariff"
  class="btn btn-primary"
  (click)="onSubmit()"
  type="submit"
>
  Assign
</button>
