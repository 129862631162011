import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageLayoutComponent } from '../page-layout/page-layout.component';
import { ReservableHoursListComponent } from './reservable-hours-list/reservable-hours-list.component';
import { AuthGuard } from '../auth/services/auth-guard/auth.guard';
import { ReservableHoursFormComponent } from './reservable-hours-form/reservable-hours-form.component';
import { ReservableHoursAssignComponent } from './reservable-hours-assign/reservable-hours-assign.component';

const ReservableHoursRoutes: Routes = [
  {
    path: 'reservable-hours',
    component: PageLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ReservableHoursListComponent },
      { path: 'create', component: ReservableHoursFormComponent },
      { path: 'assign', component: ReservableHoursAssignComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ReservableHoursRoutes)],
  exports: [RouterModule],
})
export class ReservableHoursRoutingModule {}
