import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChargingSpacesListComponent } from './charging-spaces-list/charging-spaces-list.component';
import { AuthGuard } from '../auth/services/auth-guard/auth.guard';
import { PageLayoutComponent } from '../page-layout/page-layout.component';

const chargingSpacesRoutes: Routes = [
  {
    path: 'charging-spaces',
    component: PageLayoutComponent,
    canActivate: [AuthGuard],
    children: [{ path: '', component: ChargingSpacesListComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(chargingSpacesRoutes)],
  exports: [RouterModule],
})
export class ChargingSpacesRoutingModule {}
