import { Component, OnInit } from '@angular/core';

import { ChargingSpace } from 'src/app/interfaces/chargingSpace';
import { ChargingSpacesService } from '../services/charging-spaces.service';

@Component({
  selector: 'app-charging-spaces-list',
  templateUrl: './charging-spaces-list.component.html',
  styleUrls: ['./charging-spaces-list.component.css'],
})
export class ChargingSpacesListComponent implements OnInit {
  charging_spaces: ChargingSpace[] = [];

  constructor(private _chargingSpacesService: ChargingSpacesService) {}

  ngOnInit() {
    this._chargingSpacesService.getSpaces().subscribe((resp) => {
      this.charging_spaces = resp.data;
    });
  }
}
