import { PriceComponent, Tariff } from 'src/app/interfaces/tariff';
import {
  Reservation,
  ReservationPeriodDimensionTypeEnum,
  ReservationPeriods,
  ReservationStatus,
} from 'src/app/interfaces/reservation';

import { ChargingSpace } from 'src/app/interfaces/chargingSpace';
import { ChargingSpacesService } from '../../charging-spaces/services/charging-spaces.service';
import { Component } from '@angular/core';
import { Config } from 'src/app/interfaces/config';
import { ConfigService } from '../../config/services/config.service';
import { ReservationsService } from '../services/reservations.service';
import { TariffService } from 'src/app/tariff/services/tariff.service';

@Component({
  selector: 'app-reservations-list',
  templateUrl: './reservations-list.component.html',
  styleUrls: ['./reservations-list.component.css'],
})
export class ReservationsListComponent {
  reservations: Reservation[] = [];
  configs: Config[] = [];
  charging_spaces: ChargingSpace[] = [];
  tariffs: Tariff[] = [];

  refundModalOpen: boolean = false;
  cancelModalOpen: boolean = false;
  refundMessage: string | null = null;
  cancelMessage: string | null = null;

  constructor(
    private _reservationsService: ReservationsService,
    private _configService: ConfigService,
    private _chargingSpacesService: ChargingSpacesService,
    private _tariffsService: TariffService,
  ) {}

  ngOnInit() {
    this._reservationsService.getReservations().subscribe((resp) => {
      this.reservations = resp.data;
    });
    this._configService.getConfigs().subscribe((resp) => {
      this.configs = resp.data;
    });
    // TODO Remove once config embedded into reservation
    this._chargingSpacesService.getSpaces().subscribe((resp) => {
      this.charging_spaces = resp.data;
    });
    this._tariffsService.getTariffs().subscribe((resp) => {
      this.tariffs = resp.data;
    });
  }

  getReservationStatusLabel(status: ReservationStatus) {
    let colour;
    if (status == 'PENDING') {
      colour = 'label-grey';
    } else if (status == 'CONFIRMED') {
      colour = 'label-green';
    } else if (status == 'CANCELLED') {
      colour = 'label-orange';
    } else {
      colour = 'label-grey';
    }
    return colour;
  }

  getReservationLocation(reservation: Reservation) {
    // TODO remove once config embedded into reservation
    let reservationLocation: ChargingSpace = this.charging_spaces.filter(
      (space: ChargingSpace) => space.uid == reservation.evse_uid,
    )[0];
    return reservationLocation;
  }

  getReservationBufferTime(reservation: Reservation) {
    let reservationLocation: ChargingSpace =
      this.getReservationLocation(reservation);
    let reservationConfig: Config = this.configs.filter(
      (config: Config) => config.id == reservationLocation.config_id,
    )[0];

    if (reservationConfig && reservationConfig.buffer_period_minutes) {
      var minute_milliseconds = 60000;
      var bufferStartTime = new Date(
        new Date(reservation.start_time).valueOf() -
          reservationConfig.buffer_period_minutes * minute_milliseconds,
      );
      return bufferStartTime;
    } else {
      return reservation.start_time;
    }
  }

  getReservationDuration(startTime: Date, endTime: Date) {
    // All reservations are in integers of minutes
    let differenceMilliseconds =
      new Date(endTime).valueOf() - new Date(startTime).valueOf();
    let differenceMinutes = differenceMilliseconds / (1000 * 60);
    return differenceMinutes;
  }

  getReservationTariff(reservation: Reservation) {
    let reservationLocation: ChargingSpace =
      this.getReservationLocation(reservation);
    let reservationTariff: Tariff = this.tariffs.filter(
      (tariff: Tariff) =>
        tariff.id == reservationLocation.reservation_tariff_id,
    )[0];

    return reservationTariff;
  }

  getReservationTotalCostInclVat(reservation: Reservation) {
    let totalCost = 0;
    totalCost += reservation.reservation_fee?.incl_vat ? reservation.reservation_fee?.incl_vat : 0;
    totalCost += reservation.reserved_stay_fee?.incl_vat
      ? reservation.reserved_stay_fee?.incl_vat
      : 0;
    totalCost += reservation.early_arrival_fee?.incl_vat
      ? reservation.early_arrival_fee?.incl_vat
      : 0;
    totalCost += reservation.late_arrival_fee?.incl_vat
      ? reservation.late_arrival_fee?.incl_vat
      : 0;
    totalCost += reservation.early_departure_fee?.incl_vat
      ? reservation.early_departure_fee?.incl_vat
      : 0;
    totalCost += reservation.late_departure_fee?.incl_vat
      ? reservation.late_departure_fee?.incl_vat
      : 0;

    return totalCost;
  }

  getReservationPeriods(reservation: Reservation) {
    let reservationPeriods: ReservationPeriods[] =
      reservation.reservation_periods;

    let earlyArrivalDuration = 0;
    let lateArrivalDuration = 0;
    let reservedStayDuration = 0;
    let earlyDepartureDuration = 0;
    let lateDepartureDuration = 0;

    for (let period of reservationPeriods) {
      for (let dimension of period.dimensions) {
        switch (dimension.type) {
          case ReservationPeriodDimensionTypeEnum.EARLY_ARRIVAL_TIME:
            earlyArrivalDuration += dimension.volume;
            break;
          case ReservationPeriodDimensionTypeEnum.LATE_ARRIVAL_TIME:
            lateArrivalDuration += dimension.volume;
            break;
          case ReservationPeriodDimensionTypeEnum.RESERVED_STAY_TIME:
            reservedStayDuration += dimension.volume;
            break;
          case ReservationPeriodDimensionTypeEnum.EARLY_DEPARTURE_TIME:
            earlyDepartureDuration += dimension.volume;
            break;
          case ReservationPeriodDimensionTypeEnum.LATE_DEPARTURE_TIME:
            lateDepartureDuration += dimension.volume;
            break;
          default:
            break;
        }
      }
    }

    return [
      {
        dimension: 'Early arrival',
        time: earlyArrivalDuration * 60,
      },
      {
        dimension: 'Late arrival',
        time: lateArrivalDuration * 60,
      },
      {
        dimension: 'Reserved stay',
        time: reservedStayDuration * 60,
      },
      {
        dimension: 'Early departure',
        time: earlyDepartureDuration * 60,
      },
      {
        dimension: 'late departure',
        time: lateDepartureDuration * 60,
      },
    ];
  }

  reassignChargingSpaceForReservation() {}

  refundReservation(reservation: Reservation) {
    console.log('Refunding reservation ' + reservation.id);
    if (reservation.id) {
      this._reservationsService
        .refundReservation(reservation)
        .subscribe((resp) => {
          resp.data;
          if (resp.status_code !== 1000) {
            this.refundMessage = resp.status_message;
          } else {
            this.refundMessage = `Successfully refunded reservation ${resp.data.id} value of £${resp.data.refund_amount!.incl_vat} at ${resp.data.refund_confirmed_at}`;
          }
        });
    } else {
      this.refundMessage = 'Unable to refund, id was not a string';
      console.error(
        'ERROR: Could not refund reservation with id: ' +
          reservation.id +
          ', ID was not a string',
      );
    }
  }

  cancelReservation(reservation: Reservation) {
    // Automatically refunds in reservation service

    console.log('Cancelling reservation ' + reservation.id);
    if (reservation.id) {
      this._reservationsService
        .cancelReservation(reservation)
        .subscribe((resp) => {
          resp.data;
          if (resp.status_code !== 1000) {
            this.cancelMessage = resp.status_message;
          } else {
            this.cancelMessage = `Successfully cancelled reservation ${resp.data.id} at ${resp.data.cancelled_at}, reason was ${resp.data.cancellation_reason}`;
          }
        });
    } else {
      this.cancelMessage = 'Unable to cancel, id was not a string';
      console.error(
        'ERROR: Could not cancel reservation with id: ' +
          reservation.id +
          ', ID was not a string',
      );
    }
  }
}
