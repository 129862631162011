import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { ChargingSpace } from 'src/app/interfaces/chargingSpace';
// import { ChargingSpacesService } from '../../charging-spaces/services/charging-spaces.service';
import { Config } from 'src/app/interfaces/config';
import { ConfigService } from '../services/config.service';
import { FormState } from 'src/app/interfaces/formState';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-config-list',
  templateUrl: './config-list.component.html',
  styleUrls: ['./config-list.component.css'],
})
export class ConfigListComponent implements OnInit {
  configs!: Config[];
  charging_spaces: ChargingSpace[] = [];

  deleteFailureAlertClosed = true;
  deleteSuccessAlertClosed = true;

  constructor(
    private _configService: ConfigService,
    // private _chargingSpacesService: ChargingSpacesService,
    private _router: Router,
  ) {}

  ngOnInit() {
    this._configService.getConfigs().subscribe((resp) => {
      this.configs = resp.data;
    });

    // this._chargingSpacesService.getSpaces().subscribe((resp) => {
    //   this.charging_spaces = resp.data;
    // });
  }

  // getConfigLocations(configId: string) {
  //   return this.charging_spaces.filter(
  //     space => space.config_id === configId).map(space => space.uid);
  // }

  viewConfig(config: Config) {
    const navigationExtras: NavigationExtras = {
      state: {
        config: config,
        action: FormState.VIEW,
      },
    };

    this._router.navigate(['/configurations/create'], navigationExtras);
  }

  editConfig(config: Config) {
    const navigationExtras: NavigationExtras = {
      state: {
        config: config,
        action: FormState.EDIT,
      },
    };

    this._router.navigate(['/configurations/create'], navigationExtras);
  }

  deleteConfig(configId: string) {
    this._configService.deleteConfig(configId).subscribe({
      next: () => {
        // TODO: Should we just pop from the list instead of re-fetching for speed?
        // e.g. this.configs = this.configs.filter(config => config.id !== configId);
        this.deleteSuccessAlertClosed = false;
        this._configService.getConfigs().subscribe((resp) => {
          this.configs = resp.data;
        });
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 409) {
          // If 409, give a message saying that config is assigned.
          this.deleteFailureAlertClosed = false;
        }
      },
    });
  }
}
