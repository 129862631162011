import { ChargingSpace } from 'src/app/interfaces/chargingSpace';
import { ChargingSpacesService } from 'src/app/charging-spaces/services/charging-spaces.service';
import { Component, OnInit } from '@angular/core';
import { ReservableHours } from 'src/app/interfaces/reservableHours';
import { ReservableHoursService } from '../services/reservable-hours.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reservable-hours-assign',
  templateUrl: './reservable-hours-assign.component.html',
  styleUrls: ['./reservable-hours-assign.component.css'],
})
export class ReservableHoursAssignComponent implements OnInit {
  displayError = false;
  errorMessage = '';

  weekDays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  hours: ReservableHours[] = [];
  charging_spaces: ChargingSpace[] = [];

  selected_hours!: ReservableHours;
  selected_spaces: ChargingSpace[] = [];

  constructor(
    private _chargingSpacesService: ChargingSpacesService,
    private _reservableHoursService: ReservableHoursService,
    private _router: Router,
  ) {}

  ngOnInit() {
    this._reservableHoursService.getReservableHours().subscribe((resp) => {
      this.hours = resp.data;
    });

    this._chargingSpacesService.getSpaces().subscribe((resp) => {
      this.charging_spaces = resp.data;
    });
  }

  getPeriods(weekDay: number) {
    if (this.selected_hours) {
      return this.selected_hours.regular_hours.filter(
        (period) => period.weekday == weekDay,
      );
    } else {
      return [];
    }
  }

  displayPeriods(weekDay: number) {
    const periods = this.getPeriods(weekDay);
    const times = periods.map((period) => {
      return `${period.period_begin} - ${period.period_end}`;
    });
    return times.join(', ');
  }

  getOpenings() {
    const openings = this.selected_hours.exceptional_openings;
    const times = openings.map((opening) => {
      return {
        start: new Date(opening.period_begin),
        end: new Date(opening.period_end),
      };
    });
    return times;
  }

  getClosings() {
    const closings = this.selected_hours.exceptional_closings;
    const times = closings.map((closing) => {
      return {
        start: new Date(closing.period_begin),
        end: new Date(closing.period_end),
      };
    });
    return times;
  }

  onSubmit() {
    if (this.selected_spaces.length >= 1 && this.selected_hours) {
      this.displayError = false;
      this.errorMessage = '';

      this._reservableHoursService
        .assignReservableHours(this.selected_hours, this.selected_spaces)
        .subscribe({
          next: (resp) => {
            if (resp.status_code != 1000) {
              // Tracks errors returned from the server
              this.displayError = true;
              this.errorMessage = `Error ${resp.status_code}: ${resp.status_message}`;
            } else {
              this._router.navigate(['/reservable-hours']);
            }
          },
          // Tracks errors from the service call itself i.e. server unreachable
          error: () => {
            this.displayError = true;
          },
        });
    }
  }
}
