<clr-datagrid>
  <clr-dg-column>EVSE ID</clr-dg-column>
  <clr-dg-column>Location ID</clr-dg-column>
  <clr-dg-column>UID</clr-dg-column>
  <clr-dg-column>Config ID</clr-dg-column>
  <clr-dg-column>Reservable Hours ID</clr-dg-column>
  <clr-dg-column>Tariff ID</clr-dg-column>
  <clr-dg-column>Last Updated</clr-dg-column>

  <clr-dg-row *clrDgItems="let space of charging_spaces">
    <clr-dg-cell>{{ space.evse_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.location_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.uid }}</clr-dg-cell>
    <clr-dg-cell>{{ space.config_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.reservable_hours_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.reservation_tariff_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.last_updated | date: "d/M/yy, H:mm" }}</clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer
    >{{ charging_spaces.length }}
    {{ charging_spaces.length === 1 ? "space" : "spaces" }}</clr-dg-footer
  >
</clr-datagrid>
