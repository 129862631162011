import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClarityModule } from '@clr/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TariffAssignComponent } from './tariff-assign/tariff-assign.component';
import { TariffFormComponent } from './tariff-form/tariff-form.component';
import { TariffListComponent } from './tariff-list/tariff-list.component';
import { TariffsRoutingModule } from './tariffs-routing.module';

@NgModule({
  declarations: [
    TariffListComponent,
    TariffFormComponent,
    TariffAssignComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    TariffsRoutingModule,
  ],
})
export class TariffsModule {}
