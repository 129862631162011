<!-- Alert bar -->
<div class="alert alert-danger" role="alert" *ngIf="displayError">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
      </div>
      <span
        class="alert-text"
        *ngIf="
          errorMessage;
          then displayErrorMessage;
          else displayGenericMessage
        "
      >
      </span>
    </div>
  </div>
</div>

<ng-template #displayErrorMessage>
  {{ errorMessage }}
</ng-template>
<ng-template #displayGenericMessage>
  Something went wrong. Please try again later.
</ng-template>

<!-- Hours Selection -->
<label for="hours-select">Select Reservable Hours:</label>
<select clrSelect name="hours-select" [(ngModel)]="selected_hours">
  <option value="" disabled selected>Select reservable hours</option>
  <option *ngFor="let hour of hours" [ngValue]="hour">
    {{ hour.id }}
  </option>
</select>

<!-- Reservable Hours Details -->
<table class="table table-vertical table-compact table-rightheader">
  <tbody>
    <tr *ngFor="let day of weekDays; let i = index">
      <th>{{ day | titlecase }}</th>
      <td *ngIf="getPeriods(i + 1).length > 0; else CheckTwentyFourSeven">
        {{ displayPeriods(i + 1) }}
      </td>
    </tr>
    <tr>
      <th>Exceptional Openings</th>
      <td
        *ngIf="
          this.selected_hours &&
            this.selected_hours.exceptional_openings.length > 0;
          else DisplayEmpty
        "
      >
        <div *ngFor="let opening of getOpenings()">
          {{ opening.start | date: "dd/MM/yy, h:mm a" }} -
          {{ opening.end | date: "shortTime" }}
        </div>
      </td>
    </tr>
    <tr>
      <th>Exceptional Closings</th>
      <td
        *ngIf="
          this.selected_hours &&
            this.selected_hours.exceptional_closings.length > 0;
          else DisplayEmpty
        "
      >
        <div *ngFor="let closing of getClosings()">
          {{ closing.start | date: "dd/MM/yy, h:mm a" }} -
          {{ closing.end | date: "shortTime" }}
        </div>
      </td>
    </tr>
  </tbody>
</table>
<br />

<!-- Space selection -->
<p class="p2">Select EVSEs to apply configuration to:</p>
<clr-datagrid [(clrDgSelected)]="selected_spaces">
  <clr-dg-column>EVSE ID</clr-dg-column>
  <clr-dg-column>Location ID</clr-dg-column>
  <clr-dg-column>UID</clr-dg-column>
  <clr-dg-column>Reservable Hours ID</clr-dg-column>
  <clr-dg-column>Last Updated</clr-dg-column>

  <clr-dg-row *clrDgItems="let space of charging_spaces" [clrDgItem]="space">
    <clr-dg-cell>{{ space.evse_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.location_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.uid }}</clr-dg-cell>
    <clr-dg-cell>{{ space.reservable_hours_id }}</clr-dg-cell>
    <clr-dg-cell>{{ space.last_updated | date: "d/M/yy, H:mm" }}</clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer
    >{{ charging_spaces.length }}
    {{ charging_spaces.length === 1 ? "space" : "spaces" }}</clr-dg-footer
  >
</clr-datagrid>

<button
  [disabled]="selected_spaces.length < 1 || !selected_hours"
  class="btn btn-primary"
  (click)="onSubmit()"
  type="submit"
>
  Assign
</button>

<ng-template #CheckTwentyFourSeven>
  <td
    *ngIf="selected_hours && selected_hours.twentyfourseven; else CheckClosed"
  >
    24/7
  </td>
</ng-template>

<ng-template #CheckClosed>
  <td *ngIf="selected_hours; else DisplayEmpty">Closed</td>
</ng-template>

<ng-template #DisplayEmpty>
  <td></td>
</ng-template>
