import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClarityModule } from '@clr/angular';
import { CommonModule } from '@angular/common';
import { ConfigAssignComponent } from './config-assign/config-assign.component';
import { ConfigFormComponent } from './config-form/config-form.component';
import { ConfigListComponent } from './config-list/config-list.component';
import { ConfigRoutingModule } from './config-routing.module';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    ConfigListComponent,
    ConfigFormComponent,
    ConfigAssignComponent,
  ],
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    ConfigRoutingModule,
  ],
})
export class ConfigModule {}
