import { Observable, map } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface AppConfig {
  API_GATEWAY: string;
  IDENTITY_SERVICE: string;
  CLIENT_ID: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private config!: AppConfig;
  loaded = false;

  constructor(private _http: HttpClient) {}

  loadConfig(): Observable<void> {
    return this._http.get<AppConfig>('assets/app.config.json').pipe(
      map((data) => {
        this.config = data;
        this.loaded = true;
      }),
    );
  }

  getConfig(): AppConfig {
    return this.config;
  }
}
