<div class="main-container">
  <header class="header header-6">
    <div class="branding">
      <a routerLink="/" class="nav-link">
        <span class="title">
          <img
            height="40px"
            src="../../assets/rnc-logo-white.svg"
            alt="reserve and charge logo"
          />
        </span>
      </a>
    </div>
    <div class="header-actions">
      <clr-dropdown>
        <button class="nav-text" clrDropdownTrigger>
          {{ username }}
          <cds-icon shape="angle" direction="down"></cds-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <button (click)="logOut()" clrDropdownItem>Log out</button>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </header>
  <div class="content-container">
    <clr-vertical-nav>
      <a
        clrVerticalNavLink
        routerLink="/"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Home
      </a>
      <a
        clrVerticalNavLink
        routerLink="/reservations"
        routerLinkActive="active"
      >
        Reservations
      </a>
      <a
        clrVerticalNavLink
        routerLink="/charging-spaces"
        routerLinkActive="active"
      >
        Charging Spaces
      </a>
      <a
        clrVerticalNavLink
        routerLink="/configurations"
        routerLinkActive="active"
      >
        Configurations
      </a>
      <a clrVerticalNavLink routerLink="/tariffs" routerLinkActive="active">
        Tariffs
      </a>
      <a
        clrVerticalNavLink
        routerLink="/reservable-hours"
        routerLinkActive="active"
      >
        Reservable Hours
      </a>
    </clr-vertical-nav>
    <div class="content-area">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
