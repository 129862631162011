<!-- Alert bar -->
<div class="alert alert-danger" role="alert" *ngIf="displayError">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
      </div>
      <span
        class="alert-text"
        *ngIf="
          errorMessage;
          then displayErrorMessage;
          else displayGenericMessage
        "
      >
      </span>
    </div>
  </div>
</div>

<ng-template #displayErrorMessage>
  {{ errorMessage }}
</ng-template>
<ng-template #displayGenericMessage>
  Something went wrong. Please try again later.
</ng-template>

<h3>Create Reservable Hours</h3>

<!-- Form -->
<form clrForm [formGroup]="form">
  <clr-control-container class="clr-row">
    <label class="clr-required-mark" for="id">Name</label>
    <input clrControl type="text" id="id" formControlName="id" />
    <clr-control-error *clrIfError="'required'">
      This field is required
    </clr-control-error>
  </clr-control-container>
  <br />
  <div class="clr-row">
    <div class="clr-col-9">
      <h4>Regular Hours</h4>
    </div>
    <div class="clr-col-1">
      <h4>
        <clr-toggle-wrapper>
          <label class="clr-control-label" for="twentyfourseven">24/7</label>
          <input
            id="twentyfourseven"
            clrToggle
            formControlName="twentyfourseven"
            type="checkbox"
          />
        </clr-toggle-wrapper>
      </h4>
    </div>
  </div>
  <hr />
  <div *ngFor="let day of days">
    <clr-control-container class="clr-row" [formGroupName]="day">
      <div style="display: flex">
        <fieldset
          class="clr-col-5"
          [disabled]="this.form.get('twentyfourseven')?.value"
        >
          <clr-toggle-wrapper id="{{ day + '-toggle' }}">
            <label [for]="day" class="clr-control-label">
              {{ day | titlecase }}
            </label>
            <input
              [id]="day"
              clrToggle
              formControlName="active"
              type="checkbox"
            />
          </clr-toggle-wrapper>
        </fieldset>
        <div
          class="clr-col-6"
          formArrayName="periods"
          *ngIf="
            isActive(day) && !this.form.get('twentyfourseven')?.value;
            else CheckClosed
          "
        >
          <div
            [formGroupName]="i"
            *ngFor="
              let period of getPeriods(day).controls | slice: 0 : 1;
              let i = index
            "
          >
            <input
              id="{{ day + '-' + i + '-begin-input' }}"
              type="time"
              clrControl
              formControlName="period_begin"
            />
            to
            <input
              id="{{ day + '-' + i + '-end-input' }}"
              type="time"
              clrControl
              formControlName="period_end"
            />
          </div>
        </div>
        <div class="clr-col-1">
          <button
            id="{{ 'add-' + day + '-opening-button' }}"
            [disabled]="!isActive(day)"
            (click)="addPeriod(day)"
            type="button"
            class="btn btn-link btn-sm"
            style="margin: 0"
          >
            <cds-icon shape="plus"></cds-icon>
          </button>
        </div>
      </div>
    </clr-control-container>
    <div [formGroupName]="day">
      <div formArrayName="periods" *ngIf="isActive(day)">
        <clr-control-container
          class="clr-row"
          [formGroupName]="i + 1"
          *ngFor="
            let period of getPeriods(day).controls | slice: 1;
            let i = index
          "
        >
          <div style="display: flex">
            <!-- Empty column for spacing -->
            <div class="clr-col-5"></div>

            <div class="clr-col-3">
              <div>
                <label for="reservable_hours_start"></label>
                <input
                  id="{{ day + '-' + (i + 1) + '-begin-input' }}"
                  clrControl
                  type="time"
                  formControlName="period_begin"
                />
                to
                <input
                  id="{{ day + '-' + (i + 1) + '-end-input' }}"
                  clrControl
                  type="time"
                  formControlName="period_end"
                />
              </div>
            </div>
            <div class="clr-col-1">
              <button
                id="{{ 'delete-' + day + '-opening-button-' + (i + 1) }}"
                (click)="deletePeriod(day, i + 1)"
                type="button"
                class="btn btn-link btn-sm"
                style="margin: 0"
              >
                <cds-icon shape="trash" style="color: red"></cds-icon>
              </button>
            </div>
          </div>
        </clr-control-container>
      </div>
    </div>
    <hr />
  </div>

  <br />
  <div class="clr-row">
    <div class="clr-col-9">
      <h4>Exceptional Openings</h4>
    </div>
    <div class="clr-col-1">
      <h4>
        <button
          id="add-exceptional-opening-button"
          (click)="addOpening()"
          type="button"
          class="btn btn-link btn-sm"
          style="margin: 0"
        >
          <cds-icon shape="plus"></cds-icon>
        </button>
      </h4>
    </div>
  </div>
  <hr />

  <div
    class="clr-row"
    formGroupName="exceptional_openings"
    *ngFor="let opening of getOpenings().controls; let i = index"
  >
    <div [formArrayName]="i">
      <div style="display: flex">
        <clr-control-container class="clr-col">
          <input
            id="{{ 'exceptional-opening-' + i + '-date-input' }}"
            type="date"
            clrControl
            formControlName="date"
          />
        </clr-control-container>
        <clr-control-container class="clr-col">
          <input
            id="{{ 'exceptional-opening-' + i + '-begin-input' }}"
            type="time"
            clrControl
            formControlName="period_begin"
          />
          to
          <input
            id="{{ 'exceptional-opening-' + i + '-end-input' }}"
            type="time"
            clrControl
            formControlName="period_end"
          />
        </clr-control-container>
        <clr-control-container class="clr-col">
          <button
            (click)="deleteOpening(i)"
            type="button"
            class="btn btn-link btn-sm"
            style="margin: 0"
            id="{{ 'delete-exceptional-opening-button-' + i }}"
          >
            <cds-icon shape="trash" style="color: red"></cds-icon>
          </button>
        </clr-control-container>
      </div>
    </div>
  </div>

  <br />
  <div class="clr-row">
    <div class="clr-col-9">
      <h4>Exceptional Closings</h4>
    </div>
    <div class="clr-col-1">
      <h4>
        <button
          id="add-exceptional-closing-button"
          (click)="addClosing()"
          type="button"
          class="btn btn-link btn-sm"
          style="margin: 0"
        >
          <cds-icon shape="plus"></cds-icon>
        </button>
      </h4>
    </div>
  </div>
  <hr />
  <div
    class="clr-row"
    formGroupName="exceptional_closings"
    *ngFor="let closing of getClosings().controls; let i = index"
  >
    <div [formArrayName]="i">
      <div style="display: flex">
        <clr-control-container class="clr-col">
          <input
            id="{{ 'exceptional-closing-' + i + '-date-input' }}"
            type="date"
            clrControl
            formControlName="date"
          />
        </clr-control-container>
        <clr-control-container class="clr-col">
          <input
            id="{{ 'exceptional-closing-' + i + '-begin-input' }}"
            type="time"
            clrControl
            formControlName="period_begin"
          />
          to
          <input
            id="{{ 'exceptional-closing-' + i + '-end-input' }}"
            type="time"
            clrControl
            formControlName="period_end"
          />
        </clr-control-container>
        <clr-control-container class="clr-col">
          <button
            (click)="deleteClosing(i)"
            type="button"
            class="btn btn-link btn-sm"
            style="margin: 0"
            id="{{ 'delete-exceptional-closing-button-' + i }}"
          >
            <cds-icon shape="trash" style="color: red"></cds-icon>
          </button>
        </clr-control-container>
      </div>
    </div>
  </div>

  <br />
  <button
    [disabled]="!form.valid"
    class="btn btn-primary"
    type="submit"
    (click)="onSubmit()"
  >
    Submit
  </button>
  <button id="reset-button" class="btn" type="button" (click)="resetForm()">
    Reset
  </button>
</form>

<ng-template #CheckClosed>
  <div
    class="clr-col-6"
    *ngIf="this.form.get('twentyfourseven')?.value; else Closed"
  >
    <div>24/7</div>
  </div>
</ng-template>

<ng-template #Closed>
  <div class="clr-col-6">Closed</div>
</ng-template>
