import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/services/auth-guard/auth.guard';
import { NgModule } from '@angular/core';
import { PageLayoutComponent } from '../page-layout/page-layout.component';
import { TariffAssignComponent } from './tariff-assign/tariff-assign.component';
import { TariffFormComponent } from './tariff-form/tariff-form.component';
import { TariffListComponent } from './tariff-list/tariff-list.component';

const routes: Routes = [
  {
    path: 'tariffs',
    component: PageLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: TariffListComponent },
      { path: 'create', component: TariffFormComponent },
      { path: 'assign', component: TariffAssignComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TariffsRoutingModule {}
