import { Response, TariffArrayResponse } from 'src/app/interfaces/response';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app-config.service';
import { ChargingSpace } from 'src/app/interfaces/chargingSpace';
import { Tariff } from 'src/app/interfaces/tariff';

@Injectable({
  providedIn: 'root',
})
export class TariffService {
  private API_GATEWAY = '';
  private country_code = '';
  private party_id = '';

  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService,
    private _keycloak: KeycloakService,
  ) {
    this.API_GATEWAY = this._appConfig.getConfig().API_GATEWAY;

    const token = this._keycloak.getKeycloakInstance().tokenParsed;
    if (token) {
      this.country_code = token['country_code'];
      this.party_id = token['party_id'];
    }
  }

  getTariffs(): Observable<TariffArrayResponse> {
    const url = `${this.API_GATEWAY}/tariffs`;
    return this._http.get<TariffArrayResponse>(url);
  }

  putTariff(tariff: Tariff): Observable<Response> {
    tariff.country_code = this.country_code;
    tariff.party_id = this.party_id;
    const url = `${this.API_GATEWAY}/tariffs/${tariff.id}`;
    return this._http.put<Response>(url, tariff);
  }

  assignTariff(tariff: Tariff, spaces: ChargingSpace[]): Observable<Response> {
    const url = `${this.API_GATEWAY}/tariffs/${tariff.id}/assignments`;
    const data = {
      assignments: spaces.map((space) => ({
        location_id: space.location_id,
        evse_uid: space.uid,
      })),
    };
    return this._http.post<Response>(url, data);
  }
}
